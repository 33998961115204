import React, { useState } from 'react';
import Layout from '../components/layout/layout';
import { Table, Button, Drawer, Label, Textarea, TextInput, Modal, FileInput } from "flowbite-react";
import { HiOutlineExclamationCircle, HiMiniTrash } from "react-icons/hi2";
import { Switch } from '@headlessui/react';
import { useLocation } from 'react-router-dom';
import { useCategories } from '../hooks/useCategories';
import { useToastHandler } from '../hooks/useToastHandler';
import { updateDatabase } from '../utils/updateDatabase';

function Category() {
    const location = useLocation();
    const currentId = location.pathname.split('/').pop();

    const [categories, setCategories, setUpdateEffect] = useCategories(currentId);
    const showToast = useToastHandler();

    const [draggedItem, setDraggedItem] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [openModalId, setOpenModalId] = useState(null);
    const [editingCategory, setEditingCategory] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const token = localStorage.getItem('authToken');

    const [formData, setFormData] = useState({
        roName: '',
        enName: '',
        roDescription: '',
        enDescription: '',
        roNutrition: '',
        enNutrition: '',
        price: '',
        image: '',
        subcategories: currentId
    });

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const customDrawer = {
        "root": {
            "base": "fixed z-[60] overflow-y-auto bg-white p-4 transition-transform dark:bg-gray-800",
            "backdrop": "fixed inset-0 z-[55] bg-gray-900/50 dark:bg-gray-900/80",
            "position": {
                "right": {
                    "on": "right-0 top-0 h-screen w-full lg:w-1/3 transform-none",
                    "off": "right-0 top-0 h-screen w-full lg:w-1/3 translate-x-full"
                }
            }
        }
    };

    const handleDragStart = (e, item) => setDraggedItem(item);

    const handleDrop = (e, dropTarget) => {
        e.preventDefault();
        const newCategories = reorderCategories(draggedItem, dropTarget);
        setCategories(newCategories);
        setDraggedItem(null);
        updateDatabase(newCategories);
    };

    const reorderCategories = (draggedItem, dropTarget) => {
        const newCategories = [...categories];
        const draggedIndex = newCategories.findIndex(c => c.id === draggedItem.id);
        const targetIndex = newCategories.findIndex(c => c.id === dropTarget.id);
        newCategories.splice(draggedIndex, 1);
        newCategories.splice(targetIndex, 0, draggedItem);

        return newCategories.map((category, index) => ({ ...category, order: index }));
    };

    const handleFileChange = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'];
        const maxSize = 10 * 1024 * 1024;

        if (file) {
            if (!allowedTypes.includes(file.type)) {
                showToast("Invalid File Type", "Only JPG, JPEG, PNG, and WEBP files are allowed.", 'destructive');
            } else if (file.size > maxSize) {
                showToast("File Too Large", "The file size should not exceed 10MB.", 'destructive');
            } else {
                setSelectedFile(file);
                setPreviewUrl(URL.createObjectURL(file));
                e.target.value = null; // Clear the file input
            }
        }
    };

    const handleEdit = async (category) => {
        setPreviewUrl(null);
        setEditingCategory(category);
        setIsOpen(true);
        setFormData({
            roName: category.roName || '',
            enName: category.enName || '',
            roDescription: category.roDescription || '',
            enDescription: category.enDescription || '',
            roNutrition: category.roNutrition || '',
            enNutrition: category.enNutrition || '',
            price: category.price || '',
            image: category.image || '',
            subcategories: category.subcategories || currentId,
            order: category.order || 0, // Ensure order is set correctly
        });
        if (category.image) {
            try {
                const imageUrl = `https://admin.perfettocucinaitaliana.ro/api/public/${category.image}-960.webp`;
                setPreviewUrl(imageUrl);
            } catch (error) {
                console.error('Failed to fetch image URL:', error);
            }
        }
    };

    const handleAdd = () => {
        const maxOrder = categories.reduce((max, category) => Math.max(max, category.order), -1);
        // Reset the formData to initial state when adding a new product
        setFormData({
            roName: '',
            enName: '',
            roDescription: '',
            enDescription: '',
            roNutrition: '',
            enNutrition: '',
            price: '',
            image: '',
            subcategories: currentId,
            order: maxOrder + 1
        });
        setSelectedFile(null);
        setPreviewUrl(null);
        setIsOpen(true);
        setEditingCategory(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.roName === '' || formData.enName === '') {
            showToast("Missing Data", "Please add all necessary information", 'destructive');
            return;
        }

        let newFormData = { ...formData };

        if (selectedFile) {
            setIsUploading(true);
            try {
                const formData = new FormData();
                formData.append('file', selectedFile);

                const response = await fetch('https://admin.perfettocucinaitaliana.ro/api/upload', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }, body: formData
                });
                const data = await response.json();

                newFormData.image = data.id;
                setSelectedFile(null);
                setIsUploading(false);
            } catch (error) {
                showToast("Upload Error", error.message, 'destructive');
                setIsUploading(false);
                return;
            }
        }

        try {
            const method = editingCategory ? 'PUT' : 'POST';
            const url = editingCategory ? `https://admin.perfettocucinaitaliana.ro/api/documents/${editingCategory.id}` : 'https://admin.perfettocucinaitaliana.ro/api/documents';

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(newFormData)
            });

            if (!response.ok) throw new Error(`Failed to ${editingCategory ? 'update' : 'add'} document`);

            setIsOpen(false);
            setUpdateEffect(prev => !prev);
            setEditingCategory(null);
            showToast(`Produsul a fost ${editingCategory ? 'modificat' : 'adaugat'} cu succes`);
        } catch (error) {
            showToast("Error", `An error occurred while ${editingCategory ? 'updating' : 'adding'} the product, please contact the administrator`, 'destructive');
        }
    };

    const handleDelete = async (itemId) => {
        try {
            const response = await fetch(`https://admin.perfettocucinaitaliana.ro/api/documents/${itemId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) throw new Error('Failed to delete the item');

            setOpenModalId(null);
            setUpdateEffect(prev => !prev);
            showToast("Produsul a fost sters cu success");
        } catch (error) {
            showToast("Eroare", "Nu s-a putut sterge produsul, te rog sa contactezi administratorul", 'destructive');
        }
    };

    const handleToggle = async (categoryId, newToggleState, name) => {
        try {
            const updatedCategories = categories.map(category =>
                category.id === categoryId ? { ...category, visibility: newToggleState } : category
            );

            setCategories(updatedCategories);
            await updateDatabase(updatedCategories);
            showToast(name, "Vizibilitatea produsului a fost actualizata cu succes");
        } catch (error) {
            showToast("Error", "Failed to update toggle", 'destructive');
        }
    };

    const handleImageDelete = () => {
        setFormData({ ...formData, image: null });
        setSelectedFile(null);
        setPreviewUrl(null);
    };

    return (
        <Layout>
            <div className="flex justify-end w-full py-2 items-right">
                <Button color='light' onClick={handleAdd}>Adauga produs</Button>
            </div>
            <Drawer open={isOpen} onClose={() => setIsOpen(false)} position="right" theme={customDrawer}>
                <Drawer.Header title="Adauga produs" titleIcon={() => <></>} />
                <Drawer.Items>
                    <form onSubmit={handleSubmit}>
                        <div className="mt-3 mb-6">
                            <Label htmlFor="roName" className="block mb-2">Nume | RO</Label>
                            <TextInput id="roName" name="roName" placeholder="Nume romana" value={formData.roName} onChange={(e) => setFormData({ ...formData, roName: e.target.value })} />
                        </div>
                        <div className="mb-6">
                            <Label htmlFor="enName" className="block mb-2">Nume | EN</Label>
                            <TextInput id="enName" name="enName" placeholder="Nume engleza" value={formData.enName} onChange={(e) => setFormData({ ...formData, enName: e.target.value })} />
                        </div>
                        <div className="mb-6">
                            <Label htmlFor="roDescription" className="block mb-2">Descriere | RO</Label>
                            <Textarea id="roDescription" name="roDescription" placeholder="Descriere in romana" value={formData.roDescription} onChange={(e) => setFormData({ ...formData, roDescription: e.target.value })} />
                        </div>
                        <div className="mb-6">
                            <Label htmlFor="enDescription" className="block mb-2">Descriere | EN</Label>
                            <Textarea id="enDescription" name="enDescription" placeholder="Descriere in engleza" value={formData.enDescription} onChange={(e) => setFormData({ ...formData, enDescription: e.target.value })} />
                        </div>
                        <div className="mb-6">
                            <Label htmlFor="roNutrition" className="block mb-2">Alergeni | RO</Label>
                            <Textarea id="roNutrition" name="roNutrition" placeholder="Alergeni in romana" value={formData.roNutrition} onChange={(e) => setFormData({ ...formData, roNutrition: e.target.value })} />
                        </div>
                        <div className="mb-6">
                            <Label htmlFor="enNutrition" className="block mb-2">Alergeni | EN</Label>
                            <Textarea id="enNutrition" name="enNutrition" placeholder="Alergeni in engleza" value={formData.enNutrition} onChange={(e) => setFormData({ ...formData, enNutrition: e.target.value })} />
                        </div>
                        <div className="mb-6">
                            <Label htmlFor="price" className="block mb-2">Pret</Label>
                            <TextInput id="price" name="price" placeholder="Pret" value={formData.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })} />
                        </div>
                        <Label
                            htmlFor="dropzone-file"
                            className={`flex flex-col items-center justify-center w-full h-64 border-2 overflow-hidden relative ${isDragOver ? 'border-blue-500' : 'border-gray-300'} border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600`}
                            onDragOver={(e) => {
                                e.preventDefault();
                                setIsDragOver(true);
                            }}
                            onDragLeave={(e) => {
                                e.preventDefault();
                                setIsDragOver(false);
                            }}
                            onDrop={(e) => {
                                e.preventDefault();
                                setIsDragOver(false);
                                const file = e.dataTransfer.files[0];
                                setSelectedFile(file);
                                setPreviewUrl(URL.createObjectURL(file));
                            }}
                        >
                            {previewUrl ? (
                                <>
                                    <img src={previewUrl} alt="Preview" className='object-cover w-full h-full' />
                                    <HiMiniTrash type="button" className="absolute w-6 h-6 p-1 font-medium text-white bg-red-500 rounded-full top-1 right-1" onClick={handleImageDelete} />
                                </>
                            ) : (
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        <span className="font-semibold">Click to upload</span> or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">JPG, JPEG, PNG, WEBP (MAX. 10MB)</p>
                                </div>
                            )}
                            <FileInput id="dropzone-file" className="hidden" onChange={handleFileChange} />
                        </Label>
                        <div className="my-6">
                            <Button color='light' type="submit" className="w-full" disabled={isUploading}>
                                {isUploading ? 'Uploading...' : (editingCategory ? 'Update' : 'Adauga')}
                            </Button>
                        </div>
                    </form>
                </Drawer.Items>
            </Drawer>
            <div className="overflow-x-auto">
                <Table hoverable>
                    <Table.Head>
                        <Table.HeadCell>Imagine</Table.HeadCell>
                        <Table.HeadCell>Nume produs</Table.HeadCell>
                        <Table.HeadCell>Pret</Table.HeadCell>
                        <Table.HeadCell>Vizibilitate</Table.HeadCell>
                        <Table.HeadCell><span className='sr-only'>Action</span></Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {categories !== undefined && categories.map((category) => (
                            <Table.Row
                                key={category.id}
                                draggable="true"
                                onDragStart={(e) => handleDragStart(e, category)}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleDrop(e, category)}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                            >
                                <Table.Cell className='flex w-20 p-0 m-2 overflow-hidden rounded-lg aspect-square'>
                                    {category.image ? <img className='object-cover w-full rounded-sm' alt={category.name} src={`https://admin.perfettocucinaitaliana.ro/api/public/${category.image}-300.webp`} /> : null}
                                </Table.Cell>
                                <Table.Cell className="font-medium text-gray-900 cursor-move whitespace-nowrap dark:text-white">
                                    {category.roName}
                                </Table.Cell>
                                <Table.Cell><span className='font-bold '>{category.price}</span></Table.Cell>
                                <Table.Cell>
                                    <Switch
                                        checked={category.visibility}
                                        onChange={() => handleToggle(category.id, !category.visibility, category.roName)}
                                        className={classNames(
                                            category.visibility ? 'bg-indigo-600' : 'bg-gray-200',
                                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                                        )}
                                    >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                            className={classNames(
                                                category.visibility ? 'translate-x-5' : 'translate-x-0',
                                                'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                            )}
                                        >
                                            <span
                                                className={classNames(
                                                    category.visibility ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                                                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                )}
                                                aria-hidden="true"
                                            >
                                                <svg className="w-3 h-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                                    <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                            <span
                                                className={classNames(
                                                    category.visibility ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                                                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                )}
                                                aria-hidden="true"
                                            >
                                                <svg className="w-3 h-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                </svg>
                                            </span>
                                        </span>
                                    </Switch>
                                </Table.Cell>
                                <Table.Cell>
                                    <div className='flex'>
                                        <Button color="dark" size="xs" className="ml-2" onClick={() => handleEdit(category)}>Edit</Button>
                                        <Button onClick={() => setOpenModalId(category.id)} color="failure" size="xs" className="ml-2">Delete</Button>
                                    </div>
                                    <Modal show={openModalId === category.id} size="md" onClose={() => setOpenModalId(null)} popup>
                                        <Modal.Header />
                                        <Modal.Body>
                                            <div className="text-center">
                                                <HiOutlineExclamationCircle className="mx-auto mb-4 text-gray-400 h-14 w-14 dark:text-gray-200" />
                                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                                    Esti sigur ca vrei sa stergi acest produs? Actiunea este ireversibila.
                                                </h3>
                                                <div className="flex justify-center gap-4">
                                                    <Button color="failure" onClick={() => handleDelete(category.id)}>Da, sunt sigur</Button>
                                                    <Button color="gray" onClick={() => setOpenModalId(null)}>Nu, anuleaza</Button>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        </Layout>
    );
}

export default Category;
